/* containers */
.container-footer {
    background-color: #1c1c1e;
    padding: 30px;
}

/* text */
.chinese_text {
    color: white;
    font-size: 1.5rem;
    margin: 0px;
}

.inside_points {
    background-image: url("../assets/horizontal_points.png");
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
    height: 3px;
}

.section-footer {
    color: #FFFFFF;
    opacity: .5;
    text-align: center;
    margin: 0px;
}

/* images */

.social-image {
    margin: 5px;
}

/* media */

@media (max-width: 767.98px) { 
    .container-footer {
        display: none !important;
    }

}

@media (max-width: 1199.98px) {
    .social-image {
        transform: scale(.8);
    }
    .chinese_text {
        font-size: 1.8vw;
    }
    .section-footer {
        font-size: 1.5vw;
    }
}