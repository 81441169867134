@import "./Constants.css";

.modal-content {
    background: transparent !important;
    background: rgba(1,1,1,1);
}

.fade {
    padding: 0px !important;
}

.modal-dialog {
    width: 100% !important;
}

.modal-header {
    border: 0px;
}

.modal-title {
    color: white;
    font-size: var(--title-size);
    margin: 0px;
    font-family: var(--avenir-medium);
    border: 0px;
}

/* text */

.search {
    color: white;
    font-size: var(--title-size);
    margin: 0px;
    font-family: var(--avenir-medium);
}

input {   
    border: none;
    --webkit-appearance: none;
    --ms-apperance: none;
    --mz-apperance: none;
    appearance: none;
    color: white;
    font-size: var(--title-size);
    font-family: var(--avenir-medium);
    background: transparent;
}

input:focus {
    outline: none;
}