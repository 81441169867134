@font-face {
    font-family: "Avenir";
    src: local("Avenir"),
    url("../assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir-Medium";
    src: local("Avenir-Medium"),
    url("../assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Serif";
    src: local("Serif"),
    url("../assets/fonts/NotoSerifTC-Regular.otf") format("opentype");
}

/* containers */
.person-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5), 
    rgba(0, 0, 0, 0.5));
    border-radius: 15px;
    font-family: 'Serif';
    font-weight: 700;
}

.drop-container-inside {
    display: flex;
    justify-content: center;
}
