@import "./Constants.css";

/* containers */

/* chrome-safari-opera */

.video-controls-container {
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, .3),
    rgba(0, 0, 0, .3),
    rgba(0, 0, 0, .4),
    rgba(0, 0, 0, .5),
    rgba(0, 0, 0, .6));
}

.video-container-parent {
    
}

.video-description-text-container::-webkit-scrollbar-thumb {
    background-color: gray;
}

.video-description-container {
    width: 100%;
    height: 100%;
    padding-right: 25px;
    overflow-y: auto;
    position: absolute;
}

.video-description-container::-webkit-scrollbar {
    height: 8px !important;
    overflow: visible !important;
    width: 10px !important;
}

.video-description-container::-webkit-scrollbar-thumb {
    background: #2b272d !important;
    background-clip: padding-box !important;
    border: 2px solid transparent !important;
    border-radius: 100px;
    border-image: initial !important;
    min-height: 28px !important;
    padding: 100px 0px 0px !important;
}

.video-description-container::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 1px 0px 5px -2px rgba(0,0,0,0.3);
    border-radius: 100px;
}

.video-description-text-container::-webkit-scrollbar-corner {
    background: transparent !important;
}

.video-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.video-description-video-container {
    width: 100%;
    max-height: 600px;
    object-fit: contain;
    position: relative;
}

.slider-icons-container {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, .3),
    rgba(0, 0, 0, .3),
    rgba(0, 0, 0, .4),
    rgba(0, 0, 0, .6),
    rgba(0, 0, 0, .7),
    rgba(0, 0, 0, .7));
}

/* firefox */
.video-description-text-container {
    scrollbar-color: #2c272f black;
    scrollbar-width: thin;
}

/* text */

.video-description-category {
    color: var(--category-color);
    opacity: .5;
    font-size: var(--category-detail-size);
    font-family: var(--avenir-medium);
    margin: 0px;
}

.video-description-title {
    color: white;
    font-size: var(--title-detail-size);
    font-family: var(--avenir-medium);
    margin: 0px;
}

.video-description-date {
    color: white;
    font-size: .8em;
    margin: 0px;
}

.video-description-author {
    color: white;
    font-size: var(--date-detail-size);
    margin: 0px;
}

.video-description-tag {
    color: #FD4463;
    font-size: 1em;
    margin: 0px 10px 0px 0px;
}

.video-description-text {
    color: white;
    font-size: var(--content-size);
    font-family: var(--avenir-medium);
    line-height: 30px;
    white-space: pre-wrap;
}

/* slider */

/* general style */

.range-container-4 {
    background-color: transparent;
}

.range-container-24 input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 3px;
    padding: 0;
    background: transparent;
    border-radius: 100px;
    background-image: linear-gradient(white, white);
    background-size: 0% 100%;
    background-repeat: no-repeat;
}

/* chrome - opera - safari */
.range-container-24 input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    background: transparent;
}

/* firefox */
.range-container-24 input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-border-radius: 5px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background: transparent;
    border: none;
}

  
/* slider */

.MuiSlider-rail .css-1n40zqk-MuiSlider-track {
    background-color: white;
    width: 100%;
    height: 4px;
 
}

.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    box-shadow:none !important;
}

.css-w79lal-MuiSlider-root {
    position: static;
}

/* button */

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

/* media */

@media (max-width: 991.98px) { 
    .video-container-parent {
        height: 600px !important;
    }
    .video-description-text-container {
        max-height: 200px;
    }
    .video-description-container {
        position: relative;
    }
    .video-info-container {
        padding-top: 0px;
    }
    .video-description-video-container {
        margin-top: 30px !important;
    }
}