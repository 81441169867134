/* containers */

.responsive-publications {
    background-color: transparent;
}

/* media */
@media (max-width: 767.98px) { 
    .responsive-publications {
        width: 100% !important;
    }
 }


