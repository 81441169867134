/* containers */
.slider-container {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 2vh;
}

.slider-container-audio-general {
    height: 100px;
    width: 100%;
    background-color: red;
    display: none;
}

.responsive-icons-container-general-2 {
    width: 100%;
    display: none !important;
    margin-bottom: 10px;
}


.audio-controls-container-general {
    display: flex;
}

.cassette-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -18px;
    scale: 1;
}

.triangle-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    scale: 1;
    display: none;
    top: -30px;
}

.parent-container-audio {
    position: fixed; 
    left: 0;
    bottom: 0;
    width: 100vw;
    z-index: 2000;
    display: flex;
    background-color: #4c4c56;
    flex-direction: column;
    display: none;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

/* button */

.volume-button {
    all: unset;
    color: white;
    text-align: center;
    padding: 5px;
    margin-bottom: 3px;
}

/* slider package style */

#range-slider .range-slider {
    height: 3px;
}

#range-slider .range-slider__thumb {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    background-color: white;
}

#range-slider .range-slider__thumb[data-lower] {
    width: 0;
}

#range-slider .range-slider__range {
    border-radius: 100px;
  	background-color: white;
}

/* text */
.time {
    color: white;
    margin: 0px;
    font-size: 1em;
}

/* media */
@media (max-width: 575.98px) {
    .slider-container {
        bottom: 3vh
    }
    .cassette-container {
        scale: .8;
        top: -10px;
    }
    .triangle-container {
        top: -18px;
    }
}


@media (max-width: 767.98px) {

}

@media (max-width: 1399.98px) { 
    .slider-container-audio-general {
        position: fixed;
        right: 0%;
        left: 0%;
        bottom: -60px;
        transition-property: bottom;
        transition-duration: 1s;
        z-index: 2000;
        padding: 10px;
        background-color:#4c4c56;
        border-bottom-right-radius: 40px;
        border-bottom-left-radius: 40px;
        display: flex;
        flex-direction: column;
    }
    .responsive-icons-container-general-2 {
        display: flex !important;
        margin-bottom: 10px;
    }
    .audio-controls-container-general {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
