@import url("./Constants.css");

@font-face {
    font-family: "Avenir";
    src: local("Avenir"),
    url("../assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir-Medium";
    src: local("Avenir-Medium"),
    url("../assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Serif";
    src: local("Serif"),
    url("../assets/fonts/NotoSerifTC-Regular.otf") format("opentype");
}

/* containers */
.vision-content-container {
    padding: 30px 10vw 0px 10vw;
    /* padding-top: 30px; */
}

.vision-title-container {
    display: flex;
    align-items: center;
    padding-left: 7vw;
    padding-right: 7vw;

}

.vision-content-container h1 {
    margin: 0px;
    font-family: "Avenir-Medium";
    font-size: var(--header-content);
    letter-spacing: 3px;
}

.red-line-container {
    background-image: url("../assets/red_line.png");
    background-position: left;
    background-repeat: no-repeat;
    height: 70px;
    background-size: 70px;
    width: 80px;
}   

.author-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-author-container {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.fr-img-wrap {
    display: flex;
    justify-content: center;
}

/* images */
.fr-img-wrap img {
    width: 100%;
    max-width: 80%;
    max-height: 80vh;
    object-fit: contain;

}

.fr-video .fr-dvb .fr-draggable {
    width: 100%;
    max-width: 100%;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

iframe {
    width: 80%;
    height: 100%;
}

/* yt-video */

.video-container {
    width: 100%;
    min-width: 100%;
    justify-content: center;
    display: flex;
    aspect-ratio: 16/9;
}

/* text */

.content-text {
    /* padding: 0px 11vw 0px 11vw; */
    font-size: var(--content-size);
    font-family: "Serif";
    display: block;
    width: 100%;
    padding-left: 10vw;
    padding-right: 10vw;
    line-height: 30px;
}

strong {
    color: #FD4463;
    font-weight: bold;
}

/* media */
@media (max-width: 575.98px) { 
    .vision-content-container {
        padding: 30px 0px 0px 0px;
    }
    .content-text {
        padding: 0px 10vw 0px 10vw;
    }
}

@media (max-width: 767.98px) { 
    
    .fr-video iframe {
        width: 100vw !important;
        margin: -12px;
    }
    .fr-img-wrap img {
        
        max-width: 100vw !important;
        width: 100vw;

    }
    .author-container {
        width: 100%;
    }
        
    .vision-title-container {
        padding-left: 0;
        padding-right: 0;
        margin-left: -12px;

    }
}




