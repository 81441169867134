/* containers */

.video-short-container {
}

.short-info-container {    
  background: linear-gradient(to bottom, 
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, .4),
  rgba(0, 0, 0, .4),
  rgba(0, 0, 0, .4),
  rgba(0, 0, 0, .8),
  rgba(0, 0, 0, .8),
  rgba(0, 0, 0, .8));
  position: absolute;
  z-index: 1;
  width: 100%;
  min-height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

/* text */
.category-short {
  color: white;
  font-size: .8em;
  margin: 0px;
  margin-bottom: 10px;
}

.title-short {
  color: white;
  font-size: 1.2em;
  margin: 0px;
  margin-bottom: 10px;
}

.description-short {
  color: white;
  font-size: 1em;
  margin: 0px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
}

.description-short-break {
  color: white;
  font-size: 1em;
  margin: 0px;
  white-space: pre-line;
  padding-top: 20px;
}

/* play-pause-icon */

.play-pause-icon {
  animation: fadeOut 3s normal;
}

.play-pause-icon2 {
  animation: fadeOut 3s normal;
}

/* swiper */
.swiper {
  width: 100%;
  height: 100%;
}
  
.swiper-slide {
  /* text-align: center; */
  background: black;
  /* Center slide text vertically */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* slider */

/* general style */

.range-container-4 {
  background-color: transparent;
}

.range-container-3 input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 3px;
  padding: 0;
  background: transparent;
  border-radius: 100px;
  background-image: linear-gradient(white, white);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

/* chrome - opera - safari */
.range-container-3 input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  background: transparent;
}

/* firefox */
.range-container-3 input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-border-radius: 5px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background: transparent;
  border: none;
}

/* media */

@media (max-width: 767.98px) { 
  .video-short-container {
    display: block;
  }
}


/* keyframes */

@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}