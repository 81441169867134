@import "./Constants.css";

/* container */
.parent-container-latest-carousel {
    /* margin-bottom: 30px; */
    /* flex-grow: 1; */
    width: 100%;
    min-height: 100px;
    overflow: hidden;
}

.responsive-container {
    width: 160px;
    height: 220px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    -webkit-transform: translate3d(0, 0, 0);
}

.no-responsive-container {
    aspect-ratio: 9/16;
    min-height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    -webkit-transform: translate3d(0, 0, 0);
}

.no-responsive-container-spotlight {
    width: 254px;
    height: 410px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    /* -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden; */
    -webkit-transform: translate3d(0, 0, 0);
}

.feature-container {
    aspect-ratio: 1080/945;
    height: 500px;
    position: relative;
    overflow: hidden;
}

.latest-container {
    background-image: linear-gradient(to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0), 
   rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5), 
    rgba(0, 0, 0, 0.6))
}


.latest-container-hot-spot {
    background-image: linear-gradient(to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0), 
   rgba(0, 0, 0, 0.0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, .7),
    rgba(0, 0, 0, 1), 
    rgba(0, 0, 0, 1))
}


/* text */
.category-audio {
    font-size: 1.5em;
    color: white;
    margin: 0px;
}

.title-audio {
    font-size: 2em;
    color: white;
    margin: 0px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 10px;
}

.outline-audio {
    font-size: 1.3em;
    color: white;
    margin: 0px;
    line-height: 40px;
}

.date-audio {
    font-size: .8em;
    color: white;
    margin: 0px;
    margin-bottom: 5px;
    margin-right: 10px;
}


.feature-name {
    color: white;
    margin: 0px;
    font-size: calc(var(--title-detail-size) + .5rem);
    font-family: var(--serif);
}

.feature-title {
    color: white;
    margin: 0px;
    font-size: var(--title-size);
    font-family: var(--serif);
}

/* media */


@media (max-width: 767.98px) { 
    .parent-container-latest-carousel {
        width: 100vw !important;
        overflow: hidden;
    }
    .feature-container {
        height: 350px;
    }
    .feature-name {
        font-size: var(--title-detail-size);
    }
    .feature-title {
        font-size: var(--navbar-size);
    }
}
