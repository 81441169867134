@import "./Constants.css";

@font-face {
    font-family: "Avenir";
    src: local("Avenir"),
    url("../assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir-Medium";
    src: local("Avenir-Medium"),
    url("../assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Serif";
    src: local("Serif"),
    url("../assets/fonts/NotoSerifTC-Regular.otf") format("opentype");
}


/* containers */
.thumbnail-container-audio-description {
    /* widht: "100%", maxHeight: "600px", aspectRatio: 9/16, objectFit: "contain", position: "relative", flexWrap: "wrap" */
    width: 100%;
    max-height: 600px;
    aspect-ratio: 9/16;
    object-fit: contain;
    position: relative;
    flex-wrap: wrap;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.inner-date-voting-audio-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.audio-info-container-responsive {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    overflow: hidden;
}

.slider-container-audio {
    height: 100px;
    width: 100%;
}

.responsive-icons-container {
    width: 100%;
    display: none;
    margin-bottom: 10px;
}

.audio-controls-container {
    display: flex;
}

.cassette-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -18px;
    scale: 1;
}

.info-audio-container-responsive {
    width: 100%;
    display: none;
    flex-direction: column;
    position: relative;
}

.info-audio-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.date-voting-audio-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.time-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.audio-description-container::-webkit-scrollbar {
    height: 8px !important;
    overflow: visible !important;
    width: 10px !important;
}

.audio-description-container::-webkit-scrollbar-thumb {
    background: #2b272d !important;
    background-clip: padding-box !important;
    border: 2px solid transparent !important;
    border-radius: 100px;
    border-image: initial !important;
    min-height: 28px !important;
    padding: 100px 0px 0px !important;
}

.audio-description-container::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 1px 0px 5px -2px rgba(0,0,0,0.3);
    border-radius: 100px;
}

.audio-description-text-container::-webkit-scrollbar-corner {
    background: transparent !important;
}

/* text */
.audio-description-category {
    color: var(--category-color);
    margin: 0px;
    font-size: var(--category-detail-size);
    opacity: .5;
    font-family: "Avenir-Medium";
}

.audio-description-title {
    color: var(--title-color);
    margin: 0px;
    font-size: var(--title-detail-size);
    font-family: "Avenir-Medium";
}

.audio-description-person {
    color: var(--title-color);
    margin: 0px;
    font-size: var(--date-detail-size);
    font-family: "Avenir-Medium";
}

.audio-description-date {
    color: var(--title-color);
    margin: 0px;
    font-size: var(--date-detail-size);
    font-family: "Avenir-Medium";
}

.audio-description-artist {
    color: white;
    margin: 0px;
    font-size: .8rem;
    font-family: "Avenir-Medium";
}

.audio-description-description {
    color: var(--title-color);
    margin: 0px;
    font-size: var(--content-size);
    white-space: pre-wrap;    
    font-family: "Avenir-Medium";
    padding-bottom: 30px;
    line-height: 30px;
}

.audio-description-time {
    color: white;
    margin: 0px;
    font-size: .6rem;
    font-family: "Avenir-Medium";
}

/* custom slider */

.range-container-4 input[type="range"]:hover {
    cursor: pointer;
}

.range-container-4 input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 3px;
    padding: 0;
    background: transparent;
    border-radius: 100px;
    background-image: linear-gradient(white, white);
    background-size: 0% 100%;
    background-repeat: no-repeat;
}

/* chrome - opera - safari */
.range-container-4 input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    background: transparent;
}

/* firefox */
.range-container-4 input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-border-radius: 5px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background: transparent;
    border: none;
}

/* custom slider mobile */

.range-container-5 input[type="range"]:hover {
    cursor: pointer;
}

.range-container-5 input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 1px;
    padding: 0;
    background: gray;
    border-radius: 100px;
    background-image: linear-gradient(white, white);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

/* chrome - opera - safari */
.range-container-5 input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 100px;
    background: white;
}

/* firefox */
.range-container-5 input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-border-radius: 5px;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: white;
    border: none;
}

.button-image img { 
    width: 18px;
    height: 18px;
    margin-right: 15px;
    object-fit: contain;
}

/* media */

@media (max-width: 575.98px) { 
    .thumbnail-container-audio-description {
        max-height: 200px !important;
    }
}

@media (max-width: 991.98px) { 
    .thumbnail-container-audio-description {
        max-height: 300px;
    }
    .audio-info-container-responsive {
        height: 500px;
        max-height: 600px;
    }
    .inner-date-voting-audio-container {
        flex-direction: row;
    }
}

@media (max-width: 767.98px) {
    .audio-description-title {
        margin-bottom: 10px;
    }
    .info-audio-container {
        display: none;
    }
    .date-voting-audio-container {
        display: none;
    }
    .info-audio-container-responsive {
        display: flex;
    }
    .slider-container-audio {
        /* position: fixed;
        right: 0%;
        left: 0%;
        bottom: -60px;
        transition-property: bottom;
        transition-duration: 1s;
        z-index: 1000;
        padding: 10px;
        background-color:#4c4c56;
        border-bottom-right-radius: 40px;
        border-bottom-left-radius: 40px; */
        height: 50px;
        margin-bottom: 20px;
    }
    .responsive-icons-container {
        /* display: flex;
        margin-bottom: 10px; */
    }
    .audio-controls-container {
        /* justify-content: flex-start;
        align-items: center; */
    }
    .time-container {
        /* margin: 0px; */
    }

    /* text */
    .audio-description-category {
        font-size: .6rem;
    }

    .audio-description-title {
        font-size: 1.2rem;
    }

    .audio-description-date {
        font-size: .7rem;
    }
}
