/* general style */

@keyframes rotateAnimation {
	from {transform: rotate3d(0.1, 1, 0.1, 0deg);}
	to {transform: rotate3d(0.1, 1, 0.1, 359deg);}
}

@keyframes rotateAnimation2 {
	from {transform: translateY(-100%);}
	to {transform: translateY(50vh);}
}

#marquee-parent-container .marquee-container {
    overflow-x: visible !important;
}

.rotate-audio-2 {
    animation: rotateAnimation2 10s linear infinite;
}

.rotate-audio {
    animation: rotateAnimation 6s ease-in-out infinite;
    transform-style: preserve-3d;
    box-shadow: -40px 80px 80px -10px rgba(0, 0, 0, 0.7);
    margin-top: 15px;
    object-fit: cover;
}

.range-container-volume input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 2px;
    padding: 0;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    background-image: linear-gradient(white, white);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

/* chrome - opera - safari */
.range-container-volume input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 100px;
    background: white;
}

/* firefox */
.range-container-volume input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-border-radius: 5px;
    height: 8px;
    width: 8px;
    border-radius: 100px;
    background: white;
    border: none;
}

/* chrome - opera - safari */

.range-container-slider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.range-container-slider input[type="range"] {
    -webkit-appearance: none;
    height: 10px;
    border-radius: 0px;
    border: 1px solid white;
    background: transparent;
    background-image: linear-gradient(white, white);
    background-size: 0% 100%;
    background-repeat: no-repeat;
}

.range-container-slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 0px;
    width: 0px;
    border-radius: 100px;
    background: white;
}

/* firefox */
.range-container-slider input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-border-radius: 5px;
    height: 0px;
    width: 0px;
    border-radius: 100px;
    background: white;
    border: none;
}

.volume-button {
    all: unset;
    color: white;
    text-align: center;
    padding: 5px;
}

.duration {
    font-size: .5rem;
    color: white;
}
