@import "./Constants.css";

/* container */
.related-articles-container {
    
}

/* firefox */
.related-articles-container {
    scrollbar-color: black #1c1c1e;
    scrollbar-width: thin;
}

.related-item {
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, .3),
    rgba(0, 0, 0, .3), 
    rgba(0, 0, 0, .3));
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.related-item-background {
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, .3),
    rgba(0, 0, 0, .3), 
    rgba(0, 0, 0, .3));
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;

}

/* chrome */
.related-scroll-container::-webkit-scrollbar {
    height: 8px !important;
    overflow: visible !important;
    width: 10px !important;
}
    
.related-scroll-container::-webkit-scrollbar-thumb {
    background: #2b272d !important;
    background-clip: padding-box !important;
    border: 2px solid transparent !important;
    border-radius: 100px;
    border-image: initial !important;
    min-height: 28px !important;
    padding: 100px 0px 0px !important;
}
    
.related-scroll-container::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 1px 0px 5px -2px rgba(0,0,0,0.3);
    border-radius: 100px;
}
    
.related-scroll-container::-webkit-scrollbar-corner {
    background: transparent !important;
}

/* firefox */

.related-articles-container {
    -moz-appearance: none !important;
    scrollbar-width: thin !important;
}
/* text */
.date-article {
    color: #FFFFFF;
    font-size: var(--date-size);
    font-family: var(--avenir-medium);
}

.category-article {
    color: #FFFFFF;
    font-size: var(--category-size);
    margin: 0px 0px 0px 0px;
    font-family: var(--avenir-medium);
}

.title-article {
    color: white;
    font-size: var(--title-size);
    font-family: var(--avenir-medium);
    margin: 0px 0px 0px 0px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}