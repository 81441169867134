@font-face {
    font-family: "Avenir-Medium";
    src: local("Avenir-Medium"),
    url("../assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}

/* text */
.like {
    color: white;
    margin: 0px 5px 0px 5px;
    font-size: .7rem;
    font-family: "Avenir-Medium";
}

/* @media (max-width: 767.98px) { 
    .like {
        font-size: 2vw !important;
    }
}

@media (max-width: 991.98px) { 
    .like {
        font-size: 1.3vw;
    }
} */
