@font-face {
    font-family: "Avenir";
    src: local("Avenir"),
    url("../assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir-Medium";
    src: local("Avenir-Medium"),
    url("../assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}


@font-face {
    font-family: "Serif";
    src: local("Serif"),
    url("../assets/fonts/NotoSerifTC-Regular.otf") format("opentype");
}

:root {
    --navbar-background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(28, 32, 33, 1) 15%);
    --navbar-size: .9rem;
    --avenir-medium: "Avenir-Medium";
    --serif: "Serif";
    --avenir: "Avenir";
    --category-color: #7E7E7E;
    --category-size: .7rem;
    --title-color: #FFFFFF;
    --title-size: 1rem;
    --date-color: #7E7E7E;
    --date-size: .7rem;

    --category-detail-size: .9rem;
    --title-detail-size: 1.5rem;
    --date-detail-size: .8rem;
    --tag-detail-size: .8rem;
    --introduction: .8rem;
    --outline-detail-size: .8rem;
    --header-content: 1.3rem;
    --title-audio-cassette-size: 2rem;

    --content-size: 1rem;
}