@import "./Constants.css";

@font-face {
    font-family: "Avenir";
    src: local("Avenir"),
    url("../assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir-Medium";
    src: local("Avenir-Medium"),
    url("../assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Serif";
    src: local("Serif"),
    url("../assets/fonts/NotoSerifTC-Regular.otf") format("opentype");
}


/* containers */

.parent-container {
}

.thumbnail-info-container-parent {
    max-height: 600px;
    overflow: hidden;
    margin-bottom: 30px;
}

.thumbnail-container-vision {
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
}

.info-container-responsive-parent {
    width: 100%;
    min-height: 100px;
    height: 500px;
    position: relative;
    justify-content: flex-end;
    align-items: flex-end;
    display: none;
    background-position: 50% 35%;
    background-size: cover;
    background-repeat: no-repeat;
}

.linear {
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, .8),
    rgba(0, 0, 0, .8),
    rgba(0, 0, 0, .8),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1));
    width: 100%;
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
}

.author-container-responsive {
    width: 100%;
    position: absolute;
    z-index: 3;
    height: 15px;
    bottom: 0;
    display: none !important;
}

.info-container-about-vision {
    max-height: 600px;
    display: flex;
    flex-direction: column;
}

.outline-responsive {
    width: 100%;
    padding: 20px;
    background-color: #28262F;
    display: none;
    position: relative;
}

.thumbnail-img-container-vision {
    width: 100%;
    max-height: 600px;
}

.video-title-container {
    display: flex;
    min-height: 100px;
}

/* images */
.test {
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, .0),
    rgba(0, 0, 0, .0),
    rgba(0, 0, 0, .1),
    rgba(0, 0, 0, .1),
    rgba(0, 0, 0, .1),
    rgba(0, 0, 0, .2));
}

.thumbnail-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 9/16;
}

.thumbnail-img-responsive {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 100px;
    border: 1px solid #FD4463;
    object-fit: cover;
    right: 25px;
}

/* text */
.vision-description-category {
    color: var(--category-color);
    opacity: .5;
    font-size: var(--category-detail-size);
    margin: 0px;
    margin-bottom: 10px;
    font-family: "Avenir-Medium";
}

.vision-description-title {
    color: var(--title-color);
    font-size: var(--title-detail-size);
    margin: 0px;
    margin-bottom: 10px;
    font-family: "Avenir-Medium";
}

.vision-description-date {
    color: var(--title-color);
    font-size: var(--date-detail-size);
    margin: 0px;
    margin-bottom: 5px;
    font-family: "Avenir-Medium";
}

.vision-description-tag {
    color: #FD4463;
    font-size: var(--tag-detail-size);
    font-family: "Avenir-Medium";
    margin: 0px 10px 0px 0px;
}

.vision-description-introduction {
    color: var(--category-color);
    font-size: .8rem;
    margin: 0px 0px 10px 0px;
    font-family: "Avenir-Medium";
}

.vision-description-outline {
    color: var(--title-color);
    font-size: var(--outline-detail-size);
    margin: 0px 0px 0px 10px;
    font-family: "Serif";
}

.vision-description-author {
    color: white;
    font-size: .7rem;
    margin: 0px 0px 5px 0px;
}

.vision-description-author-about {
    color: white;
    opacity: 0.5;
    font-size: .7em;
    margin: 0px 0px 0px 0px;
}

.vision-description-category-responsive {
    color: white;
    font-size: .8em;
    margin: 0px;
}

.vision-description-author-responsive {
    color: black;
    font-size: .7em;
    margin: 0px;
    margin-right: 10px;
}


/* media */

@media (max-width: 767.98px) { 
    .parent-container {
        margin: 0px !important;
        padding: 0px !important;
    }
    .thumbnail-container-vision {
        display: none !important;
    }
    .thumbnail-info-container-parent {
        padding: 0px !important;
        overflow: visible;
        margin-bottom: 0px;
    }
    .thumbnail-img-container-vision  {
        display: none !important;   
    }
    .thumbnail-img {
        display: none !important;
    }
    .info-container-responsive-parent {
        display: flex;;
    }
    .info-container-about-vision {
        display: none;
    }
    .info-container-responsive {
        display: block !important;
    }
    .author-container-responsive {
        display: block !important;
    }
    .outline-responsive {
        display: block;
    }

    /* text */

    .vision-description-category-responsive {
        color: white;
        font-size: .7rem;
        margin: 0px;
    }

    .vision-description-title {
        font-size: 1.8rem;
    }

    .vision-description-date {
        font-size: .7rem;
    }

    .vision-description-tag {
        font-size: .9rem;
    }
}


@media (max-width: 991.98px) { 
    .info-container-about-vision {
        padding-right: 50px !important;
    }
    .video-title-container {
        min-height: 0px;
    }
}
