@import "./Constants.css";

/* containers */
.video-background {
    height: 100%;
    aspect-ratio: 9/16;
    object-fit: contain;
    position: relative;
    z-index: 2;
}

.hover-background {
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, .7),
    rgba(0, 0, 0, .7), 
    rgba(0, 0, 0, .7));
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 101;    
}

.vertical-line-video {
    background-image: url("../assets/vertical_point.png");
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    width: 4px;
    height: 100%;
}

.info-video-container {
    width: 100%;
    height: 100%;
    background-image: url("../assets/gradient.png");
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    z-index: 1;
    width: 520px;
    right: 0;
    height: 100%;
    position: absolute;
}    

.index-container-video-expand {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    z-index: 100;
    height: 30px;
    display: flex;
    position: absolute;
    align-items: center;
    bottom: 0;
}

/* text */
.category-video {
    font-size: var(--category-detail-size);
    color: var(--category-color);
    font-family: var(--avenir-medium);
    margin: 0px;
}

.title-video {
    font-size: var(--title-detail-size);
    color: white;
    margin: 0px;
    font-family: var(--avenir-medium);
}

.outline-video {
    font-size: 1.2em;
    color: white;
    margin: 0px;
    line-height: 40px;
}

.date-video {
    font-size: var(--date-detail-size);
    color: white;
    margin: 0px;
    margin-right: 10px;
}

/* media */

@media (max-width: 1199.98px) {
    .index-container-video-expand {
        padding-left: calc(1vw);
    }
    .category-video {
        font-size: .8em;
    }
    .title-video {
        font-size: 1.5em;
    }
    .date-video {
        font-size: .9vw;
    }
}

@media (max-width: 1399.98px) { 
    .info-video-container {
        width: 100%;
        position: relative;
    }
}

#video-bg {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: hidden;
}

#video-bg > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
height: 100%;
}
/* 1. No object-fit support: */
@media (min-aspect-ratio: 16/9) {
    #video-bg > video { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
    #video-bg > video { width: 300%; left: -100%; }
}
/* 2. If supporting object-fit, overriding (1): */
@supports (object-fit: cover) {
    #video-bg > video {
        top: 0; left: 0;
        width: 100%; height: 100%;
        object-fit: cover;
    }
}