/* containers */
.audio-background {
    background-image: linear-gradient(to bottom, 
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4), 
    rgba(0, 0, 0, 0.4)),
    url("../assets/wave.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.cassette-container-audio {
    /* width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: blue; */
}

.carousel-buttoms {
    display: none !important;
}

.cassette-container-audio-mobile {
    position: relative;
    width: 100%;
    height: 100%;
    display: none !important;
    overflow: hidden;
}

.info-container-audio {

}

/* text */
.category-audio {
    font-size: 1.5em;
    color: white;
    margin: 0px;
}

.title-audio {
    font-size: 2em;
    color: white;
    margin: 0px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 10px;
}

.outline-audio {
    font-size: 1.3em;
    color: white;
    margin: 0px;
    line-height: 40px;
}

.date-audio {
    font-size: .8em;
    color: white;
    margin: 0px;
    margin-bottom: 5px;
    margin-right: 10px;
}

/* media */

@media (max-width: 767.98px) {
    .carousel-buttoms {
        display: flex !important;
    }
    .cassette-container-audio {
        display: none !important;
    }
    .info-container-audio {
        display: none !important;
    }
    .cassette-container-audio-mobile {
        display: inline-block !important;
    }
}