/* containers */
.navbar-visionheader-container {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.drop-a-line-container {
    display: flex;
    flex-direction: column;
    width: 10%;
    justify-content: center;
    align-items: center;
}

.home-drop-container {
    display: flex;
    height: 100vh;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: scroll;
}

.home-drop-container::-webkit-scrollbar {
    height: 8px !important;
    overflow: visible !important;
    width: 10px !important;
}

.home-drop-container::-webkit-scrollbar-thumb {
    background: #2b272d !important;
    background-clip: padding-box !important;
    border: 2px solid transparent !important;
    border-radius: 100px;
    border-image: initial !important;
    min-height: 28px !important;
    padding: 100px 0px 0px !important;
}

.home-drop-container::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 1px 0px 5px -2px rgba(0,0,0,0.3);
    border-radius: 100px;
}

.home-drop-container::-webkit-scrollbar-corner {
    background: transparent !important;
}


/* media */
@media (max-width: 767.98px) {
    .home-drop-container {
        overflow: visible;
    }
}

@media (max-width: 991.98px) {
    .navbar-visionheader-container {
        width: 100%;
        margin-right: 0px;
    }
    .drop-a-line-container {
        display: none;
    }
}
