@import "./Constants.css";

/* containers */
.horizontal_points_vision {
    background-image: url("../assets/horizontal_points.png");
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 3px;
}

.section-title-vision {
    display: none;
}

.carousel-vision-container {
    position: relative;
    height: auto;
    margin-bottom: 60px;
}

.date-voting-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.date-post-container {
    display: none !important; 
    width: 100%; 
    height: 100%;
}

.author-container {
    position: relative;
}

/* text */

.category_vision {
    color: #FFFFFF;
    opacity: .5;
    margin: 0;
    font-size: .8rem;
}

.title_vision {
    color: var(--title-color);
    font-size: var(--title-detail-size);
    margin: 0px;
    font-family: var(--serif);
    margin-bottom: 5px;
}

.date_vision {
    color: white;
    font-size: var(--date-size);
    margin-right: 20px;
}

.tag_vision {
    color: #FD4463;
    font-size: 1rem;
}

.author_vision {
    color: white;
    font-size: 1rem;
    margin: 0;
}

.introduction_vision {
    color: white;
    font-size: .8rem;
}

.li_vision {
    color: white;
    font-size: 1rem;
}

/* carousel buttom style */

.carousel-indicators {
    width: 100% !important;
    height: 10px !important;
    position: absolute !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 20px !important;
    right: 0px !important;
    justify-content: flex-end !important;
    background-color: transparent !important;
}

.carousel-indicators [data-bs-target] {
    width: 15px !important;
    height: 4px !important; 
    background-color: transparent !important;
    opacity: 0 !important;
    border: none !important;
    margin-right: 0px !important;
    margin-left: 10px !important;
}

 .carousel-indicators .active{
    width: 15px !important;
    height: 4px !important; 
    background-color: transparent !important;
    opacity: 1 !important;
    border: none !important;
    border-radius: 10px !important;
} 

/* media queries */
@media (max-width: 991.98px) { 
    .date-voting-container > span{
        font-size: 1.7vw;
    }
}

@media (max-width: 768px) {
    .visionHeaderItem {
    aspect-ratio: unset  !important;
    height: calc(100vw / 2400 * 888) !important;
    background-position: center;
    }
    .date-voting-container {
        display: none;
    }
    .author-container {
        display: none;
    }
}

@media (max-width: 768px) {
    .date-post-container {
        display: flex !important;
        font-size: .8em;
        margin-bottom: 5px;
    }
}

@media (max-width: 991.98px) { 
    .carousel-vision-container {
        margin-bottom: 0px;
    }
    .section-title-vision {
        display: block;
    }
}
