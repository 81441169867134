/* containers */

.category-container {
    background-image: linear-gradient(to bottom, 
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5), 
    rgba(0, 0, 0, 0.5));
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-bottom: 40px;
    margin-bottom: 30px;
}

/* text */

.category-name {
    font-size: 1.8em;
    color: white;
    margin: 0px;
    margin-left: 20px;
}

.category-name-chinese {
    font-size: 1em;
    color: white;
    margin: 0px;
    margin-left: 20px;
}