/* containers */

.main-container-video-content {

}

/* media */


@media (max-width: 767.98px) { 
    .main-container-video-content {
        display: none !important;
    }
}
