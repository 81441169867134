/* containers */

.video-carousel-container {
}

/* media */

@media (max-width: 767.98px) { 
    .video-carousel-container {
        display: none;
    }
}
