@import "./Constants.css";

.cassette {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 3/4;
}

.index-container {
    background-image: url("../assets/vertical_point.png");
    background-repeat: repeat;
    background-position: center center;
    background-size: contain;
    height: 100%;
    width: 3px;
}

.index-number {
    font-size: var(--title-size);
    color: white;
    margin: 0px;
    font-family: var(--avenir-medium);
    user-select: none;
}

.title-cassette {
    font-size: var(--title-size);
    color: white;
    margin: 0px;
    margin-bottom: 3px;
    font-family: var(--avenir-medium);
    user-select: none;
}

.episode-cassette {
    font-size: var(--title-size);
    color: white;
    margin: 0px;
    margin-bottom: 5px;
    font-family: var(--avenir);
    user-select: none;
}

.date-duration-cassette {
    font-size: var(--date-size);
    color: white;
    margin: 0px;
    margin-bottom: 5px;
    font-family: var(--avenir-medium);
    user-select: none;
}