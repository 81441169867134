@import "./Constants.css";

/* container */
.info-container-thumbnail{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.info-container {
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, .4),
    rgba(0, 0, 0, .4), 
    rgba(0, 0, 0, .4));
    width: 100%;
    height: 100%;
    padding-left: 26%;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    /* padding-top: 30px;
    padding-bottom: 30px; */
}

.big-play-button img {
    width: 40px;
    height: 40px;
}

/* text */
.category-info-audio {
    color: white;
    margin: 0px;
    font-size: var(--category-detail-size);
    font-family: var(--avenir-medium);
}

.title-info-audio {
    color: white;
    margin: 0px;
    font-size: var(--title-audio-cassette-size);
    font-family: var(--serif);
    font-weight: bold;
    margin-bottom: 30px;
}

.episode-info-audio {
    color: white;
    margin: 0px;
    font-size: var(--title-detail-size);
    font-family: var(--serif);
}

.date-info-audio {
    color: white;
    margin: 0px;
    font-size: var(--date-detail-size);
    margin-right: 10px;
    font-family: var(--avenir-medium);
}