@import "./Constants.css";

/* text */

.category {
    color: var(--category-color);
    opacity: .5;
    font-size: var(--category-size);
    margin: 0px 0px 5px 0px;
}
.publication-img {
    height: 120px;
}

.title {
    color: var(--title-color);
    font-size: var(--title-size);
    margin: 0px 0px 5px 0px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: var(--avenir-medium);
}

.date {
    color: var(--date-color);
    opacity: .5;
    font-size: var(--date-size);
    margin: 0px;
    font-weight: bold;
    font-family: var(--avenir-medium);
}


@media (max-width: 767.98px) { 
    .publication-img {
        height: 100px;
    }
    .category {
        font-size: .6rem;
    }
    .title {
        font-size: .8rem;
    }
    .date {
        font-size: .6rem
    }
}