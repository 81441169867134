.container-image {
    background-image: url("../assets/horizontal_points.png");
    background-repeat: repeat-x;
    background-position: center center;
    background-size: contain;
    height: 3px;
}

.section {
    margin: 0px;
    margin-right: 10px;
    color: #FFFFFF;
    opacity: .5;
    font-size: .8rem;
}

