@import "./Constants.css";

/* containers */
.main-container {
    height: 100px;
    display: flex;
    padding: 0px;
    margin-bottom: 30px;
    background: var(--navbar-background);
}

.main-container-vision {
    height: 100px;
    display: flex;
    padding: 0px;
    margin-bottom: 30px;
    background: var(--navbar-background);
}

.main-container-video {
    height: 100px;
    display: flex;
    padding: 0px;
    margin-bottom: 30px;
    background: var(--navbar-background);
}

.icon-container {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1px;
}

.audio-border {
    position: relative;
}

.audio-border::before {
    content: '';
    height: 40%;
    width: 1px;
    position: absolute;
    left: 0; 
    background-color: white;
}

.category-container-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.category-container-menu button {
    margin: 0px !important;
}

.responsive-menu-container-options {
    width: 90% !important;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: none;
}

.responsive-menu-container-icons {
    width: 10% !important;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 10px;
    display: none;
}

.small-menu-responsive {
    position: absolute;
    width: 100vw;
    padding-top: 4vh;
    z-index: 300;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 20px;
    display: none;
}

/* .audio-border::after {
    content: '';
    height: 40%;
    width: 1px;
    position: absolute;
    right: 0; 
    background-color: white;
} */

/* text */
.categories {
    font-size: var(--navbar-size);
    color: #FFFFFF;
    opacity: .5;
    margin: 0px;
}

.categories:hover {
    cursor:default;
    opacity: 1;
    color: #FD4463;
}

.categories-responsive {
    font-size: 1rem;
    color: #FFFFFF;
    margin: 0px;
}

.categories-responsive-container.active::before  {
    display: block;
    content: '';
    width: 100%;
    height: 3px;
    background-color: #bc422c;
    border-radius: 100px;
}

/* media */
@media (max-width: 767.98px) { 
    .small-menu-responsive {
        display: block;
    }
    .main-container-vision {
        display: none;
    }
    .main-container-video {
        position: fixed;
        top: 0;
        z-index: 100;
        background: transparent;
    }
}

@media (max-width: 991.98px) { 
    .category-container-menu {
        display: none;
    }
    .responsive-menu-container-options {
        display: inline-flex;
    }
    .responsive-menu-container-icons {
        display: inline-flex;
    }
    .main-container-vision {
        height: 60px;
    }
    .main-container {
        background: black;
        margin-bottom: 0px;
        height: 60px;
    }
    .main-container-video {
        margin-bottom: 30px;
        height: 60px;
    }
}

@media (max-width: 1399.98px) { 
    .categories {
        font-size: var(--navbar-size);
    }
}
