@import "./Constants.css";

/* containers */

.latest-items-container::-webkit-scrollbar {
    height: 8px !important;
    overflow: visible !important;
    width: 10px !important;
}

.latest-items-container::-webkit-scrollbar-thumb {
    background: #2b272d !important;
    background-clip: padding-box !important;
    border: 2px solid transparent !important;
    border-radius: 100px;
    border-image: initial !important;
    min-height: 28px !important;
    padding: 100px 0px 0px !important;
}

.latest-items-container::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 1px 0px 5px -2px rgba(0,0,0,0.3);
    border-radius: 100px;
}

.latest-items-container::-webkit-scrollbar-corner {
    background: transparent !important;
}

.latest-video-container-fade {
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, .7),
    rgba(0, 0, 0, .7), 
    rgba(0, 0, 0, .7));
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
}

.latest-video-container {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
}

/* text */

.latest-video-category-fade {
    font-size: var(--category-size);
    color: white;
    margin: 0px;
    font-family: var(--avenir-medium);
    margin-bottom: 10px;
}

.latest-video-title-fade {
    font-size: var(--title-size);
    font-family: var(--avenir-medium);
    color: white;
    margin: 0px;
    margin-bottom: 10px;
}

.latest-video-date-fade {
    font-size: var(--date-size);
    color: white;
    font-family: var(--avenir-medium);
    margin: 0px;
    margin-bottom: 10px;
}

.latest-video-category {
    font-size: var(--category-size);
    color: white;
    font-family: var(--avenir-medium);
    margin: 0px 0px 5px 0px;
}

.latest-video-title {
    font-size: var(--title-size);
    color: white;
    font-family: var(--avenir-medium);
    margin: 0px 0px 5px 0px;
}

.latest-video-date {
    font-size: var(--date-size);
    color: white;
    font-family: var(--avenir-medium);
    margin: 0px 0px 5px 0px;
}
